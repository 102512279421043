<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-cloud-download font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <div class="d-flex justify-content-between flex-wrap" style="gap: 10px">
      <!-- Date -->
      <div class="d-flex justify-content-end align-items-center">
        <b-form-group class="" label="วันที่เริ่มต้น">
          <b-form-datepicker
            v-model="dateStart"
            placeholder="เลือกวันที่เริ่มต้น"
            class="date-input mr-2 mb-1"
          />
        </b-form-group>
        <b-form-group class="" label="วันที่สิ้นสุด">
          <b-form-datepicker
            v-model="dateEnd"
            placeholder="เลือกวันที่สิ้นสุด"
            class="date-input mr-2 mb-1"
          />
        </b-form-group>
        <b-form-group class="mr-2 " label="ประเภท">
          <b-form-select
            v-model="reqType"
            class="mb-1 form-control-select"
            :options="channelOptions"
          />
        </b-form-group>
        <!-- Date -->
        <b-form-group
          class="mr-2 mt-1 d-flex justify-content-end align-items-center"
        >
          <b-button style="width: 100px" variant="info" @click="getLine()">
            ค้นหา
            <i class="ms-2 fal fa-search" />
          </b-button>
        </b-form-group>
        <!-- Export -->
        <b-form-group class="d-flex justify-content-end align-items-center">
          <b-button
            style="box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mt-1"
            variant="gradient-success"
            block
            @click="exportData()"
          >
            Export
          </b-button>
        </b-form-group>
        <!-- Export -->
      </div>
    </div>
    <b-card no-body>
      <!-- <b-card-body>
            </b-card-body> -->
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center table-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px" />
            {{ scope.emptyFiltered ? 'No matching records
            found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>

        <template #cell(detailsarea)="data">
          <div>
            {{ data.item.subdistrict_name}}
            {{ data.item.district_name}}
            {{ data.item.province_name}}
          </div>
        </template>

        <template #cell(full_name)="data">
          <div>
            {{ data.item.first_name }}
            {{ data.item.last_name }}
          </div>
        </template>

        <template #cell(detail)="data">
          <div v-if="data.item.detail">
            {{ data.item.detail }}
          </div>
          <p v-else>-</p>
        </template>

        <template #cell(channel_type)="data">
          <div>
            <div>{{ data.item.channel_name }}</div>
            <div style="">
              <div v-if="data.item.line_id !== null">
                ไอดี : {{ data.item.line_id }}
              </div>
              <div v-if="data.item.link_facebook !== null">
                <a target="_blank" :href="data.item.link_facebook">Link</a>
              </div>
              <div v-if="data.item.bank_name !== null">
                {{ data.item.bank_name }}
              </div>
              <div v-if="data.item.bank_account_name !== null">
                ชื่อ : {{ data.item.bank_account_name }}
              </div>
              <div v-if="data.item.bank_account !== null">
                เลขบัญชี : {{ data.item.bank_account }}
              </div>
              <div v-if="data.item.channel_name === 'เบอร์โทรศัพท์'">
                เบอร์โทร : {{ data.item.phone_number }}
              </div>
            </div>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getLine()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getLine()"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      class=""
      ref="area"
      hide-footer
      title="รายละเอียดพื่นที่"
      header-bg-variant="gradient-success"
      title-class="custom-title"
    >
      <div class="d-block text-center">
        <b-table
          striped
          hover
          :items="items_area"
          :fields="fields_area"
        ></b-table>
      </div>

      <b-button
        class="mt-2"
        variant="outline-success"
        block
        @click="$refs['area'].hide()"
        >ปิด</b-button
      >
    </b-modal>

    <b-modal
      class=""
      ref="detail"
      hide-footer
      title="รายละเอียด"
      header-bg-variant="gradient-warning"
      title-class="custom-title"
    >
      <div class="d-block text-center">
        <b-table striped hover :items="items_detail" :fields="fields_detail">
        </b-table>
        {{ items_detail }}
      </div>
      <b-button
        class="mt-2"
        variant="outline-warning"
        block
        @click="$refs['detail'].hide()"
        >ปิด</b-button
      >
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconCloudDownload,
  VBTooltip,
  BFormDatepicker,
  // BFormCheckbox,
  BFormTextarea,
  BForm,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import { parse } from "json2csv";
import moment from "moment-timezone";
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BFormTextarea,
    BButton,
    BCardBody,
    BOverlay,
    BForm,
    BIconCloudDownload,
    BFormFile,
    BButton,
    BFormDatepicker,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      search_type: null,
      search_val: null,
      show: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: null,
      sortDesc: false,
      sortDirection: null,
      filter: null,
      filterOn: [],
      reqType: null,
      channelOptions: [
        { value: 1, text: "เบอร์โทรศัพท์" },
        { value: 2, text: "ไอดีไลน์" },
        { value: 3, text: "Facebook" },
        { value: 4, text: "บัญชีธนาคาร" },
      ],
      datasidebar: [],
      fields: [
        { key: "index", label: "ลำดับ" },
        { key: "line_displayName", label: "ชื่อไลน์" },
        { key: "full_name", label: "ชื่อ-นามสกุล ผู้แจ้ง" },
        { key: "detailsarea", label: "พื้นที่ (ตำบล/อำเภอ/จังหวัด)" },
        { key: "channel_type", label: "ประเภทช่องทาง" },
        { key: "", label: "ข้อมูล" },
        { key: "detail", label: "รายละเอียด" },
        {
          key: "created_at",
          label: "บันทึกเมื่อ",
          formatter: (value) =>
            value ? moment(value).tz("Asia/Bangkok").format("DD/MM/YYYY") : "",
        },
      ],
      fields_date: [
        { key: "date_start", label: "วันที่เริ่มต้น" },
        { key: "date_end", label: "วันที่สิ้นสุด" },
      ],
      fields_area: [
        { key: "subdistrict_name", label: "ตำบล" },
        { key: "district_name", label: "อำเภอ" },
        { key: "province_name", label: "จังหวัด" },
      ],
      show_areas: [],
      items_detail: [],
      items_channel: [],
      items_area: [],
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem("userData")),
      dateStart: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      dateEnd: moment().tz("Asia/Bangkok").format("YYYY-MM-DD"),
      selectedFile: null,
    };
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    //   this.totalRows = this.items.length

    this.getLine();
  },

  methods: {
    showarea(item) {
      this.items_area = [
        {
          subdistrict_name: item.item.subdistrict_name,
          district_name: item.item.district_name,
          province_name: item.item.province_name,
        },
      ];
      this.$refs["area"].show();
      console.log(item);
    },
    show_detail(item) {
      this.items_detail = item;
      this.$refs["detail"].show();
      console.log(item);
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    // importData() {
    //   if (this.selectedFile) {
    //     this.show = true;
    //     const formData = new FormData();
    //     formData.append("csv", this.selectedFile);

    //     // เรียกใช้ฟังก์ชันส่งข้อมูลโดยใช้ Axios
    //     this.$http
    //       .post("/", formData)
    //       .then((response) => {
    //         this.getLine();
    //         this.Success(response.data.message);
    //         this.selectedFile = null;
    //         this.show = false;
    //       })
    //       .catch((error) => {
    //         this.show = false;
    //         this.SwalError(error.response.data.message);
    //       });
    //   } else {
    //     console.error("No file selected");
    //   }
    // },
    async getLine() {
      this.show = false;
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        sdate: this.dateStart,
        edate: this.dateEnd,
        reqtype: this.reqType,
      };
      await this.$http
        .get("/infoLine/showform", { params })
        .then((response) => {
          this.show = false;
          this.items = response.data.lineFormData;
          this.totalRows = response.data.total;

          // this.onFiltered(response.data);
        })
        .catch((error) => console.log(error));
    },

    onFiltered(filteredItems) {
      this.items = filteredItems.data;
      this.totalRows = filteredItems.total;
    },

    async exportData() {
      this.show = true;
      const params = {
        sdate: this.dateStart,
        edate: this.dateEnd,
      };
      try {
        const response = await this.$http.get("/infoLine/exportexcel", {
          params,
        });
        this.show = false;
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const csvData = new Blob([bom, response.data], {
          type: "text/csv;charset=utf-8;",
        });

        // สร้างชื่อไฟล์ด้วยวันที่และเวลา
        const now = new Date();
        const dateString = now.toISOString().slice(0, 10); // YYYY-MM-DD
        const timeString = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS
        const fileName = `FormLine_${dateString}_${timeString}.csv`;

        const link = document.createElement("a");
        const url = window.URL.createObjectURL(csvData);
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.date-input {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  width: auto;
  text-align: center;
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-size: 14px;
  transition: border-color 0.3s ease;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input:hover {
  border-color: #003b7a;
}

.date-input:focus {
  border-color: #242424;
  outline: none;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-label {
  font-weight: bold;
  font-size: 14px;
  color: #343a40;
}

.custom-title {
  color: #ffffff;
}
.table-center {
  text-align: center;
}

.form-control-select {
    padding: 0.438rem 1rem !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #d8d6de !important;
    border-radius: 0.357rem !important;
}


@media (max-width: 800px) {
  .custom-card {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
